var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.ready &&
      ((_vm.chatTransitionSettingsScope &&
        _vm.chatTransitionSettingsScope === 'company') ||
        (_vm.sendSurveySettingsScope && _vm.sendSurveySettingsScope === 'company'))
  )?_c('vs-row',{staticClass:"custom-section row-section-at at-settings"},[_c('vs-col',{staticClass:"v-col-at"},[_c('vs-card',[_c('div',{staticClass:"header-card-ar-settings",attrs:{"slot":"header"},slot:"header"},[_c('vs-row',{attrs:{"vs-align":"center"}},[_c('vs-col',{attrs:{"vs-w":"8"}},[_c('h1',[_vm._v("\n              "+_vm._s(_vm.lang.chatTransitionSettings.tabsTitle[_vm.languageSelected])+"\n            ")])])],1)],1),_vm._v(" "),_c('KonaTabs',[(
            _vm.chatTransitionSettingsScope &&
              _vm.chatTransitionSettingsScope === 'company'
          )?_c('KonaTab',{attrs:{"name":_vm.lang.chatTransitionSettings.tabs.general[_vm.languageSelected],"icon":"settings","selected":true}},[_c('div',{staticClass:"vx-row top-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{staticClass:"custom-section"},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-input',{attrs:{"label":_vm.lang.chatTransitionSettings.settings.xThreshold[
                          _vm.languageSelected
                        ],"type":"number","required":"","min":0,"danger-text":_vm.lang.botMaker.addBot.engineSetting
                          .inactivityTimeError[_vm.languageSelected]},on:{"keypress":function($event){_vm.isDigit($event)}},model:{value:(_vm.xThreshold),callback:function ($$v) {_vm.xThreshold=$$v},expression:"xThreshold"}}),_vm._v(" "),_c('vs-input',{staticClass:"mt-2",attrs:{"label":_vm.lang.chatTransitionSettings.settings.yThreshold[
                          _vm.languageSelected
                        ],"type":"number","required":"","min":0,"danger-text":_vm.lang.botMaker.addBot.engineSetting
                          .inactivityTimeError[_vm.languageSelected]},on:{"keypress":function($event){_vm.isDigit($event)}},model:{value:(_vm.yThreshold),callback:function ($$v) {_vm.yThreshold=$$v},expression:"yThreshold"}})],1)],1)])])],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-button',{staticClass:"float-right ml-5",on:{"click":_vm.saveGeneral}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.save[_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('vs-button',{staticClass:"float-right cancel-btn",attrs:{"color":"danger"},on:{"click":function($event){_vm.key = new Date().getTime()}}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.cancel[_vm.languageSelected])+"\n              ")])],1)],1)]):_vm._e(),_vm._v(" "),(
            _vm.chatTransitionSettingsScope &&
              _vm.chatTransitionSettingsScope &&
              _vm.chatTransitionSettingsScope === 'company'
          )?_c('KonaTab',{attrs:{"name":_vm.lang.chatTransitionSettings.tabs.transitions[_vm.languageSelected],"icon":"redo","icon-pack":"material"}},[_c('div',{staticClass:"vx-row top-row"},[_c('vs-col',{attrs:{"vs-w":"6"}},[_c('ChatTransitionSettings',{key:_vm.key,ref:"finishSettings",attrs:{"name":"finish","title":_vm.lang.chatTransitionSettings.finish[_vm.languageSelected],"icon":'icon-check-square',"titleClass":'text-success',"params":_vm.chatTransitionSettings.finish}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-w":"6"}},[_c('ChatTransitionSettings',{key:_vm.key,ref:"transferSettings",attrs:{"name":"transfer","title":_vm.lang.chatTransitionSettings.transfer[_vm.languageSelected],"icon":'icon-repeat',"titleClass":'text-danger',"params":_vm.chatTransitionSettings.transfer}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-button',{staticClass:"float-right ml-5",on:{"click":_vm.saveTransitions}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.save[_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('vs-button',{staticClass:"float-right",attrs:{"color":"danger"},on:{"click":function($event){_vm.key = new Date().getTime()}}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.cancel[_vm.languageSelected])+"\n              ")])],1)],1)]):_vm._e(),_vm._v(" "),(
            _vm.sendSurveySettingsScope && _vm.sendSurveySettingsScope === 'company'
          )?_c('KonaTab',{attrs:{"name":_vm.lang.chatTransitionSettings.tabs.survey[_vm.languageSelected],"icon":"send","selected":false}},[_c('div',{staticClass:"vx-row top-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('ChatTransitionSettings',{key:_vm.key,ref:"surveySettings",attrs:{"name":"survey","has-survey":true,"title":_vm.lang.chatTransitionSettings.tabs.survey[_vm.languageSelected],"titleClass":'text-danger',"params":_vm.sendSurveySettings}})],1)],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-button',{staticClass:"float-right ml-5",on:{"click":_vm.saveSurvey}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.save[_vm.languageSelected])+"\n              ")]),_vm._v(" "),_c('vs-button',{staticClass:"float-right",attrs:{"color":"danger"},on:{"click":function($event){_vm.key = new Date().getTime()}}},[_vm._v("\n                "+_vm._s(_vm.lang.chatTransitionSettings.cancel[_vm.languageSelected])+"\n              ")])],1)],1)]):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }