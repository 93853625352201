<template>
  <vs-row
    style
    class="custom-section row-section-at at-settings"
    v-if="
      ready &&
        ((chatTransitionSettingsScope &&
          chatTransitionSettingsScope === 'company') ||
          (sendSurveySettingsScope && sendSurveySettingsScope === 'company'))
    "
  >
    <vs-col class="v-col-at">
      <vs-card>
        <div class="header-card-ar-settings" slot="header">
          <vs-row vs-align="center">
            <vs-col vs-w="8">
              <h1>
                {{ lang.chatTransitionSettings.tabsTitle[languageSelected] }}
              </h1>
            </vs-col>
          </vs-row>
        </div>
        <KonaTabs>
          <KonaTab
            v-if="
              chatTransitionSettingsScope &&
                chatTransitionSettingsScope === 'company'
            "
            :name="lang.chatTransitionSettings.tabs.general[languageSelected]"
            icon="settings"
            :selected="true"
          >
            <div class="vx-row top-row">
              <vs-col vs-w="12">
                <div class="custom-section">
                  <div class="vx-row">
                    <vs-col vs-w="12">
                      <vs-input
                        :label="
                          lang.chatTransitionSettings.settings.xThreshold[
                            languageSelected
                          ]
                        "
                        type="number"
                        required
                        :min="0"
                        v-model="xThreshold"
                        :danger-text="
                          lang.botMaker.addBot.engineSetting
                            .inactivityTimeError[languageSelected]
                        "
                        @keypress="isDigit($event)"
                      />
                      <vs-input
                        class="mt-2"
                        :label="
                          lang.chatTransitionSettings.settings.yThreshold[
                            languageSelected
                          ]
                        "
                        type="number"
                        required
                        :min="0"
                        v-model="yThreshold"
                        :danger-text="
                          lang.botMaker.addBot.engineSetting
                            .inactivityTimeError[languageSelected]
                        "
                        @keypress="isDigit($event)"
                      />
                    </vs-col>
                  </div>
                </div>
              </vs-col>
            </div>
            <div class="vx-row">
              <vs-col vs-w="12">
                <vs-button class="float-right ml-5" @click="saveGeneral">
                  {{ lang.chatTransitionSettings.save[languageSelected] }}
                </vs-button>
                <vs-button
                  class="float-right cancel-btn"
                  color="danger"
                  @click="key = new Date().getTime()"
                >
                  {{ lang.chatTransitionSettings.cancel[languageSelected] }}
                </vs-button>
              </vs-col>
            </div>
          </KonaTab>
          <KonaTab
            v-if="
              chatTransitionSettingsScope &&
                chatTransitionSettingsScope &&
                chatTransitionSettingsScope === 'company'
            "
            :name="
              lang.chatTransitionSettings.tabs.transitions[languageSelected]
            "
            icon="redo"
            icon-pack="material"
          >
            <div class="vx-row top-row">
              <vs-col vs-w="6">
                <ChatTransitionSettings
                  :key="key"
                  name="finish"
                  :title="lang.chatTransitionSettings.finish[languageSelected]"
                  :icon="'icon-check-square'"
                  :titleClass="'text-success'"
                  :params="chatTransitionSettings.finish"
                  ref="finishSettings"
                />
              </vs-col>
              <vs-col vs-w="6">
                <ChatTransitionSettings
                  :key="key"
                  name="transfer"
                  :title="
                    lang.chatTransitionSettings.transfer[languageSelected]
                  "
                  :icon="'icon-repeat'"
                  :titleClass="'text-danger'"
                  :params="chatTransitionSettings.transfer"
                  ref="transferSettings"
                />
              </vs-col>
            </div>
            <div class="vx-row">
              <vs-col vs-w="12">
                <vs-button class="float-right ml-5" @click="saveTransitions">
                  {{ lang.chatTransitionSettings.save[languageSelected] }}
                </vs-button>
                <vs-button
                  class="float-right"
                  color="danger"
                  @click="key = new Date().getTime()"
                >
                  {{ lang.chatTransitionSettings.cancel[languageSelected] }}
                </vs-button>
              </vs-col>
            </div>
          </KonaTab>
          <KonaTab
            v-if="
              sendSurveySettingsScope && sendSurveySettingsScope === 'company'
            "
            :name="lang.chatTransitionSettings.tabs.survey[languageSelected]"
            icon="send"
            :selected="false"
          >
            <div class="vx-row top-row">
              <vs-col vs-w="12">
                <ChatTransitionSettings
                  name="survey"
                  :key="key"
                  :has-survey="true"
                  :title="
                    lang.chatTransitionSettings.tabs.survey[languageSelected]
                  "
                  :titleClass="'text-danger'"
                  :params="sendSurveySettings"
                  ref="surveySettings"
                />
              </vs-col>
            </div>
            <div class="vx-row">
              <vs-col vs-w="12">
                <vs-button class="float-right ml-5" @click="saveSurvey">
                  {{ lang.chatTransitionSettings.save[languageSelected] }}
                </vs-button>
                <vs-button
                  class="float-right"
                  color="danger"
                  @click="key = new Date().getTime()"
                >
                  {{ lang.chatTransitionSettings.cancel[languageSelected] }}
                </vs-button>
              </vs-col>
            </div>
          </KonaTab>
        </KonaTabs>
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ChatTransitionSettings from './chat-transition/ChatTransitionSettings'

export default {
  components: {
    ChatTransitionSettings,
    KonaTabs: () => import('../../../components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('../../../components/kona-tabs/KonaTab.vue')
  },
  computed: {
    ...mapState([
      'languageSelected',
      'lang',
      'session',
      'chatTransitionSettingsScope',
      'chatTransitionSettings',
      'sendSurveySettings',
      'sendSurveySettingsScope',
      'chatGeneralSettings'
    ])
  },
  data() {
    return {
      key: new Date().getTime(),
      testFinishSettings: false,
      testTransferSettings: false,
      ready: false,
      xThreshold: null,
      yThreshold: null
    }
  },
  methods: {
    ...mapActions([
      'getCompanySettings',
      'saveCompanyChatTransitionSettings',
      'saveCompanySurveySettings',
      'saveCompanyChatGeneralSettings'
    ]),
    ...mapMutations([
      'UPDATE_CHAT_TRANSITION_SETTINGS',
      'UPDATE_COMPANY_SURVEY_SETTINGS'
    ]),
    saveSurvey() {
      let survey = this.$refs.surveySettings.getValue()
      if (survey && survey.error) return
      this.$vs.loading()
      this.saveCompanySurveySettings(survey)
        .then(async () => {
          this.$store.commit('UPDATE_COMPANY_SURVEY_SETTINGS', survey)
          this.key = new Date().getTime()
          this.$vs.notify({
            color: 'success',
            title: this.lang.chatTransitionSettings.notify.success.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.chatTransitionSettings.notify.error.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.error.text[
              this.languageSelected
            ]
          })
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    saveTransitions() {
      let finish = this.$refs.finishSettings.getValue()
      if (finish && finish.error) return
      this.$vs.loading()
      this.saveCompanyChatTransitionSettings({
        finish,
        transfer: this.$refs.transferSettings.getValue()
      })
        .then(async () => {
          this.$store.commit('UPDATE_CHAT_TRANSITION_SETTINGS', {
            finish,
            transfer: this.$refs.transferSettings.getValue()
          })
          this.key = new Date().getTime()
          this.$vs.notify({
            color: 'success',
            title: this.lang.chatTransitionSettings.notify.success.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.chatTransitionSettings.notify.error.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.error.text[
              this.languageSelected
            ]
          })
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    validateGeneralSettings() {
      return this.yThreshold > this.xThreshold
    },
    saveGeneral() {
      if (!this.validateGeneralSettings()) {
        this.$vs.notify({
          color: 'danger',
          title: this.lang.chatTransitionSettings.errorThresholdsLimits.title[
            this.languageSelected
          ],
          text: this.lang.chatTransitionSettings.errorThresholdsLimits.text[
            this.languageSelected
          ]
        })
        return
      }
      this.$vs.loading()
      this.saveCompanyChatGeneralSettings({
        xThreshold: this.xThreshold * 60 * 1000,
        yThreshold: this.yThreshold * 60 * 1000
      })
        .then(async () => {
          // this.$store.commit('UPDATE_CHAT_TRANSITION_SETTINGS', {
          //   finish: this.$refs.finishSettings.getValue(),
          //   transfer: this.$refs.transferSettings.getValue()
          // })
          this.key = new Date().getTime()
          this.$vs.notify({
            color: 'success',
            title: this.lang.chatTransitionSettings.notify.success.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.chatTransitionSettings.notify.error.title[
              this.languageSelected
            ],
            text: this.lang.chatTransitionSettings.notify.error.text[
              this.languageSelected
            ]
          })
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    isDigit(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode

      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    }
  },
  async mounted() {
    this.$vs.loading()
    await this.getCompanySettings()
    this.xThreshold = this.chatGeneralSettings.xThreshold / 60 / 1000
    this.yThreshold = this.chatGeneralSettings.yThreshold / 60 / 1000
    this.ready = true
    this.$vs.loading.close()
  }
}
</script>

<style lang="scss">
.custom-section {
  .vx-row.top-row {
    margin: 30px 5px;
  }
  &.row-section-at {
    display: flex;
    width: 100%;
    padding: 0;
    box-shadow: none;
    .v-col.v-col-at {
      margin: 0;
      width: 100%;
    }
  }
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .error-message {
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .btn-actions {
    display: flex;
    justify-content: flex-end;
  }
  .header-card-ar-settings {
    display: flex;
    min-height: 44px;
    .vs-col {
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      h1 {
        margin: auto 0;
        margin-left: 0;
      }
    }
  }

  &.at-settings {
    .vs-con-input-label {
      width: 210px;
      margin-bottom: 20px;
    }
  }
}
</style>
